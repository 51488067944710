import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  CircularProgress,
  Menu,
  Grid,
  TextField,
  IconButton,
  Typography,
  Box,
  Chip,
  Avatar,
  makeStyles,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  Tabs,
  Tab,
  Divider,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Close as CloseIcon,
  DirectionsCar as CarIcon,
  Build as BuildIcon,
  Speed as SpeedIcon,
  DateRange as DateIcon,
  VpnKey as VinIcon,
  LocalShipping as TruckIcon,
  Phone as PhoneIcon,
  Category as CategoryIcon,
  Business as BusinessIcon,
  Group as GroupIcon,
  Assignment as AssignmentIcon,
  LocationOn as LocationIcon,
} from '@material-ui/icons';
import QRCode from 'qrcode';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import AssignedEntityComponent from '../Entities/AssignedEntityComponent';
import PhotoUploadComponent from '../Uploads/PhotoUploadComponent';
import RepairComponent from './RepairsComponent';
import AddRepairButton from './NewRepair';
import EquipmentMaintenanceHistory from './MtcAndRepair/EquipmentMtcHistory'
import VehicleDamageViewer from './MtcAndRepair/VehicleDamageViewer';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: theme.spacing(3),
  },
  header: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
  imageSection: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  mainImage: {
    width: 300,
    height: 200,
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
  },
  qrCode: {
    width: 120,
    height: 120,
  },
  statusChip: {
    marginTop: theme.spacing(1),
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  tabPanel: {
    padding: theme.spacing(2),
  },
  statsGrid: {
    marginBottom: theme.spacing(3),
  },
  statCard: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  statIcon: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: '50%',
  },
  fieldGroup: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box className={other.className}>{children}</Box>}
    </div>
  );
}

const EquipmentModal = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);

  const equipmentTypes = [
    'Vehicle',
    'Trailer',
    'Mower',
    'Trimmer',
    'Jackhammer',
    'Cell Phone',
    'Tablet',
    'Key',
    'Keycard',
    'Other'
  ];

  const divisions = [
    'Customer Service',
    'Specialty',
    'Irrigation and Lighting',
    'Maintenance',
    'Landscape Install',
    'Hardscape',
    'Pool/Spa',
    'Yard',
    'Commercial',
    'ADOT',
    'Maricopa County'
  ];

  const [state, setState] = React.useState({
    equipment: {},
    isActive: false,
    assignedCrew: {},
    entity: {},
    qrCode: '',
    counter: 0,
    customQR: ''
  });

  React.useEffect(() => {
    let newState = {};
    if (props.equipment && props.equipment._id) {
      if (props.equipment.crew) {
        let ourIndex = props.crews.map(function (e) {
          return e._id;
        }).indexOf(props.equipment.crew);
        newState.assignedCrew = props.crews[ourIndex];
      }
      newState.equipment = props.equipment;
      newState.isActive = props.equipment.isActive;
    }
    setState({...state, ...newState});
  }, [props.equipment]);

  React.useEffect(() => {
    if (state.equipment && state.equipment._id) {
      let qrURL = `https://dflportal.com/qr/equipment/${state.equipment._id}`;
      var opts = {
        errorCorrectionLevel: 'H',
        type: 'image/png',
        quality: 0.5,
        margin: 3,
        color: {
          dark: "#000",
          light: "#FFF",
        }
      };
      QRCode.toDataURL(qrURL, opts, function (err, url) {
        if (err) throw err;
        setState({...state, qrCode: url, counter: state.counter++ });
      });
    }
  }, [state.equipment]);

  const handleSubmit = () => {
    setLoading(true);
    let equip = state.equipment;
    let obj = { equipment: equip };
    
    axios({
      method: 'post',
      url: `${props.url}/api/equipment/updateEquipment`,
      data: obj,
    }).then(response => {
      setLoading(false);
      if (response.data._id) {
        props.notification({
          type: 'success',
          title: 'Saved Changes to that Equipment!',
          message: 'Well Done!!'
        });
        handleCloseModal();
      } else {
        props.notification({
          type: 'warning',
          title: 'Uh Oh!',
          message: response.data.message
        });
      }
    }).catch(err => {
      console.error('Error saving equipment:', err);
    });
  };

  const handleCloseModal = () => {
    props.updateParent('closeModal');
  };

  const updateEquipmentDivision = (event, value) => {
    setState({...state, equipment: {...state.equipment, division: value}});
  };

  const updateEquipmentType = (event, value) => {
    setState({...state, equipment: {...state.equipment, type: value}});
  };

  const updateAssignedCrew = (event, crew) => {
    if (crew && crew._id) {
      setState({
        ...state,
        assignedCrew: crew,
        equipment: {
          ...state.equipment,
          crew: crew._id,
          crewName: crew.name
        }
      });
    } else {
      setState({
        ...state,
        assignedCrew: {},
        equipment: {
          ...state.equipment,
          crew: null,
          crewName: 'None'
        }
      });
    }
  };

  const handleUpdateAssignedEntity = (newEntity) => {
    if (newEntity && newEntity._id) {
      setState({...state, equipment: {...state.equipment, entity: newEntity._id }});
    } else {
      setState({...state, equipment: {...state.equipment, entity: null }});
    }
  };

  const handleUpdateResponse = (resp) => {
    if (resp && resp.filesUploaded && resp.filesUploaded.length) {
      let ourFile = resp.filesUploaded[0];
      let item = {...state.equipment};
      item.pictureURL = ourFile.url;
      setState({...state, equipment: item });
    }
  };

  const handleChangeStatus = (e) => {
    setState({...state, equipment: {...state.equipment, status: e.target.value }});
  };

  const handleAddRepair = (data) => {
    console.log('Add Repair..', data);
  };

  const handleLogMtc = () => {
    const route = `/vehiclemaintenance/${state.equipment._id}`;
    history.push({ pathname: route, props: { equipment: state.equipment }});
  };

  const renderVehicleFields = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Year"
          value={state.equipment.year || ''}
          onChange={(e) => setState({...state, equipment: {...state.equipment, year: e.target.value}})}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Manufacturer"
          value={state.equipment.manufacturer || ''}
          onChange={(e) => setState({...state, equipment: {...state.equipment, manufacturer: e.target.value}})}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Model"
          value={state.equipment.model || ''}
          onChange={(e) => setState({...state, equipment: {...state.equipment, model: e.target.value}})}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="License Plate"
          value={state.equipment.plate || ''}
          onChange={(e) => setState({...state, equipment: {...state.equipment, plate: e.target.value}})}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Mileage"
          value={state.equipment.mileage || ''}
          onChange={(e) => setState({...state, equipment: {...state.equipment, mileage: e.target.value}})}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="VIN"
          value={state.equipment.vin || ''}
          onChange={(e) => setState({...state, equipment: {...state.equipment, vin: e.target.value}})}
        />
      </Grid>
    </Grid>
  );

  const renderPhoneFields = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Phone Number"
          value={state.equipment.phoneNumber || ''}
          onChange={(e) => setState({...state, equipment: {...state.equipment, phoneNumber: e.target.value}})}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="IMEI"
          value={state.equipment.imei || ''}
          onChange={(e) => setState({...state, equipment: {...state.equipment, imei: e.target.value}})}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="MAC Address"
          value={state.equipment.mac || ''}
          onChange={(e) => setState({...state, equipment: {...state.equipment, mac: e.target.value}})}
        />
      </Grid>
    </Grid>
  );

  return (
    <Dialog fullScreen open={props.open} onClose={handleCloseModal}>
      <Box className={classes.header}>
        <IconButton className={classes.closeButton} onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
        <Box className={classes.headerContent}>
          <CategoryIcon fontSize="large" />
          <Box>
            <Typography variant="h5">
              {state.equipment.name || 'Equipment Details'}
            </Typography>
            <Typography variant="subtitle1">
              #{state.equipment.number}
            </Typography>
          </Box>
        </Box>
      </Box>

      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={3}>
          {/* Left Column */}
          <Grid item xs={12} md={4}>
            <Paper className={classes.section}>
              <Box p={2}>
                <PhotoUploadComponent
                  message="Click Here to Add a Photo (Or Drop Image File Here)"
                  equipment={state.equipment}
                  updateParent={handleUpdateResponse}
                />
                <Box className={classes.imageSection}>
                  {state.equipment.pictureURL && (
                    <img
                      src={state.equipment.pictureURL}
                      alt={state.equipment.name}
                      className={classes.mainImage}
                    />
                  )}
                  {state.qrCode && (
                    <img
                      src={state.qrCode}
                      alt="QR Code"
                      className={classes.qrCode}
                    />
                  )}
                </Box>
                
                <TextField
                  select
                  fullWidth
                  label="Status"
                  value={state.equipment.status || ''}
                  onChange={handleChangeStatus}
                  variant="outlined"
                >
                  <MenuItem value="In Service">In Service</MenuItem>
                  <MenuItem value="Out for Repair">Out for Repair</MenuItem>
                  <MenuItem value="On Customer Site">On Customer Site</MenuItem>
                  <MenuItem value="On Loan">On Loan</MenuItem>
                </TextField>

                <Box className={classes.statCard} mt={2}>
                  <Avatar className={classes.statIcon}>
                    <SpeedIcon />
                  </Avatar>
                  <Box>
                    <Typography variant="body2" color="textSecondary">
                      Current Mileage
                    </Typography>
                    <Typography variant="h6">
                      {state.equipment.mileage?.toLocaleString() || '0'} mi
                    </Typography>
                  </Box>
                </Box>
                <Box mt={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<AssignmentIcon />}
                    onClick={handleLogMtc}
                    style={{ marginTop: 16 }}
                  >
                    Log Maintenance
                  </Button>
                  
                  <Box mt={1}>
                    <AddRepairButton 
                      equipmentId={state?.equipment._id} 
                      equipmentName={state?.equipment.name}
                      onAddRepair={handleAddRepair}
                    />
                  </Box>
                </Box>
              </Box>
            </Paper>

            <Paper className={classes.section}>
              <Box p={2}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  <BusinessIcon />
                  Organization
                </Typography>
                
                <Autocomplete
                  fullWidth
                  options={divisions}
                  value={state.equipment.division}
                  onChange={updateEquipmentDivision}
                  renderInput={(params) => (
                    <TextField {...params} label="Division" variant="outlined" />
                  )}
                />

                <Box mt={2}>
                  <Autocomplete
                    fullWidth
                    options={props.crews}
                    getOptionLabel={(option) => option.name}
                    value={state.assignedCrew}
                    onChange={updateAssignedCrew}
                    renderInput={(params) => (
                      <TextField {...params} label="Assigned Crew" variant="outlined" />
                    )}
                  />
                </Box>

                <Box mt={2}>
                  <AssignedEntityComponent 
                    entity={state.equipment.entity} 
                    title={'Assigned Entity'} 
                    disabled={loading} 
                    user={props.user} 
                    updateParent={handleUpdateAssignedEntity} 
                  />
                </Box>
              </Box>
            </Paper>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={8}>
            <Paper>
              <Tabs
                value={tabValue}
                onChange={(e, newValue) => setTabValue(newValue)}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Details" />
                <Tab label="Repair History" />
                <Tab label="Maintenance History" />
                <Tab label="Damage History" />
                <Tab label="Notes & Settings" />
              </Tabs>

              <TabPanel value={tabValue} index={0} className={classes.tabPanel}>
                <Box className={classes.fieldGroup}>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    <CategoryIcon />
                    Basic Information
                  </Typography>
                  
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Equipment Name"
                        value={state.equipment.name || ''}
                        onChange={(e) => setState({...state, equipment: {...state.equipment, name: e.target.value}})}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        options={equipmentTypes}
                        value={state.equipment.type}
                        onChange={updateEquipmentType}
                        renderInput={(params) => (
                          <TextField {...params} label="Equipment Type" variant="outlined" />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box className={classes.fieldGroup}>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    {state.equipment.type === 'Vehicle' ? <CarIcon /> : 
                     state.equipment.type === 'Cell Phone' ? <PhoneIcon /> : 
                     <BuildIcon />}
                    Equipment Details
                  </Typography>
                  
                  {state.equipment.type === 'Vehicle' && renderVehicleFields()}
                  {state.equipment.type === 'Cell Phone' && renderPhoneFields()}
                </Box>

                <Box className={classes.fieldGroup}>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    <LocationIcon />
                    Location Information
                  </Typography>
                  <TextField
                    fullWidth
                    label="Location"
                    value={state.equipment.location || ''}
                    onChange={(e) => setState({...state, equipment: {...state.equipment, location: e.target.value}})}
                  />
                </Box>
              </TabPanel>

              <TabPanel value={tabValue} index={1} className={classes.tabPanel}>
                {state.equipment._id && (
                  <RepairComponent equipmentId={state.equipment._id} />
                )}
              </TabPanel>
              <TabPanel value={tabValue} index={2} className={classes.tabPanel}>
              {state.equipment._id && (
                  <EquipmentMaintenanceHistory 
                    equipmentId={state.equipment._id}
                    url={props.url}
                  />
                )}
              </TabPanel>
              <TabPanel value={tabValue} index={3} className={classes.tabPanel}>
            
                  <VehicleDamageViewer 
                    equipmentId={state?.equipment?._id}
                    url={props.url}
                  />
              </TabPanel>
              <TabPanel value={tabValue} index={4} className={classes.tabPanel}>
                <Box className={classes.fieldGroup}>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    Notes
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    label="Equipment Notes"
                    value={state.equipment.notes || ''}
                    onChange={(e) => setState({...state, equipment: {...state.equipment, notes: e.target.value}})}
                  />
                </Box>

                <Box className={classes.fieldGroup}>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    Settings
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.isActive}
                        onChange={(e) => setState({
                          ...state,
                          isActive: !state.isActive,
                          equipment: {...state.equipment, isActive: !state.isActive}
                        })}
                      />
                    }
                    label={state.isActive ? 'Equipment Active' : 'Equipment Inactive'}
                  />
                </Box>

                {state.equipment.type === 'Vehicle' && (
                  <Box className={classes.fieldGroup}>
                    <Typography variant="h6" className={classes.sectionTitle}>
                      QR Code
                    </Typography>
                    <TextField
                      fullWidth
                      label="Custom QR Code"
                      value={state.equipment.qrCode || ''}
                      onChange={(e) => setState({
                        ...state,
                        customQR: e.target.value,
                        equipment: {...state.equipment, qrCode: e.target.value}
                      })}
                    />
                  </Box>
                )}
              </TabPanel>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  url: state.url,
  user: state.user,
  notification: state.notification
});

export default connect(mapStateToProps)(EquipmentModal);
