import React, { Component } from 'react'
import styles from './navigation.module.scss'
import axios from 'axios'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { setTheme, setTasks } from '../../actions/connectionActions'
import { Link } from 'react-router-dom'
import { AppBar, Toolbar, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
// import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GroupIcon from '@material-ui/icons/Group';
import EventNoteIcon from '@material-ui/icons/EventNote';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import HomeIcon from '@material-ui/icons/Home';
import DomainIcon from '@material-ui/icons/Domain';
import ListIcon from '@material-ui/icons/ListAlt';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PeopleIcon from '@material-ui/icons/People';
import {useHistory, useLocation  } from 'react-router-dom'
import DashboardIcon from '@material-ui/icons/Dashboard';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import SettingsIcon from '@material-ui/icons/Settings';
import BuildIcon from '@material-ui/icons/Build';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PrintIcon from '@material-ui/icons/Print';
import BulletIcon from '@material-ui/icons/FormatListBulleted';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import TimeSheetIcon from '@material-ui/icons/QueryBuilder';
import ProtectedRoute from '../../actions/protectedRoute'
import EntityIcon from '@material-ui/icons/Domain';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/QueryBuilder';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import InvoiceIcon from '@material-ui/icons/Receipt';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Badge from '@material-ui/core/Badge';
// import AssignmentIcon from '@material-ui/icons/Assignment';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import CreateTaskModal from '../Tasks/CreateTaskModal';
import { socket } from '../SocketIo/Socket'
import Grid from '@material-ui/core/Grid'
import moment from 'moment';
import ViewTaskModal from '../Tasks/ViewTaskModal'
import CloudUploadRoundedIcon from '@material-ui/icons/CloudUploadRounded';
import VendorsIcon from '@material-ui/icons/Business';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import Tooltip from '@material-ui/core/Tooltip';
import CreatePOModal from '../PurchaseOrders/CreatePOModal'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ProtectedButton from '../../actions/protectedButtons';
import CreateNewEstimateModal from '../Proposals/CreateNewEstimateModal'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import { setClients, setUser } from '../../actions/connectionActions'
import LocationCityIcon from '@material-ui/icons/LocationCity';
import CreateTaskComponent from '../Tasks/NewTaskComponent'
import Avatar from '@material-ui/core/Avatar';
import InputMask from "react-input-mask";
import ChatIcon from '@material-ui/icons/Chat';
import ChatDrawer from '../Common/Chat/ChatDrawer'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import DFLMtcDashboard from '../MtcRoutes/DFLMtcDashboard';
// import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PoolMtcIcon from '@material-ui/icons/Pool';
import { PhoneAndroid } from '@material-ui/icons';
// import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PageviewIcon from '@material-ui/icons/Pageview';
// import AssignmentIcon from '@material-ui/icons/Assignment';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0
  },
  menuButton: {
    marginRight: theme.spacing(2),
    marginBottom: '15px'
  },
  title: {
    flexGrow: 1,
  },
  search: {
    width: '300px'
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '2px solid red'
  },
  flexGrow: {
    display: 'flex'
  },
  popover: {
    // pointerEvents: 'none',
  },
  logoArea: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignSelf: 'center',
    // border: '2px solid red',
    // backgroundColor: 'green',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    // [theme.breakpoints.down('sm')]: {
    //   backgroundColor: 'blue',
    //   display: 'none'
    // },
  }
}));

const TaskSummary = ({ tasks, handlePopoverClose, handleViewTaskResponse, handleRouteChange }) => {
// console.log('Task Summary: ', tasks)
  const [activeTask, setActiveTask] = React.useState({})
  // const [showTaskDetailsModal, setShowTaskDetailsModal] = React.useState(false)
  
  const handleOpenTask = (task) => {
    // console.log('open this', task)
    
    // console.log(history)
    // history.push(`/tasks/${task._id}`)
    // setActiveTask(task)
    // setShowTaskDetailsModal(true)
    handlePopoverClose(task)
  }
  
  
  const handleViewTasks = () => {
    handlePopoverClose()
    handleRouteChange('/tasks')
  }

  // const handleCloseTaskModal = () => {
  //   setShowTaskDetailsModal(false)
  // }

  return (
    <div style={{ minWidth: 200, maxWidth: 400, minHeight: 150, maxHeight: 500, overflowY: 'scroll', padding: 8, textAlign: 'center' }}  >
      {/* <ViewTaskModal open={showTaskDetailsModal} task={activeTask} closeModal={handleCloseTaskModal} updateParent={handleViewTaskResponse} /> */}
      <div style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'space-between', flexDirection: 'row'}}>
        <CreateTaskComponent />
        {/* <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleCreateTask}
          >
          Create Task
        </Button> */}
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={handleViewTasks}
          >
          View My Tasks
        </Button>
      </div>
      {
        tasks.map(task => (
          <div
            style={{
              maxHeight: 90,
              border: '1px solid #bbb',
              borderRadius: 5,
              padding: 8,
              margin: 5,
              backgroundColor: 'rgba(172,202,205, .6)',
              cursor: 'pointer',
              color: 'rgb(81,110,118)'
            }}
            key={task._id}
            onClick={e => handleOpenTask(task)}
            >
              {/* <Link
                to={`/tasks/${task._id}`}
              > */}
              <div
                style={{
                  maxHeight: 50,
                  overflow: 'hidden'
                }}
                >
                {task.details}
              </div>
              <span style={{ padding: 5, marginRight: 20, textAlign: 'left', color: '#888', fontSize: 10}}>
                {`Created: ${moment(task.created_at).fromNow(true)} ago`}
              </span>
              <span style={{ padding: 5, marginLeft: 20, textAlign: 'right', color: '#888', fontSize: 10}}>
                {`Due: ${moment(task.dueDate).format('MM/DD/YYYY hh:mm a')}`}
              </span>
              

              {/* </Link> */}
              </div>

        ))
      }

    </div>
  )
}

function Navigation (props) {
  // console.log('user navigation', props)
  props.setTheme('light') 
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileOpen, setProfileOpen] = React.useState(false);
  const history = useHistory()
  const [anchorElTasks, setAnchorElTasks] = React.useState(null);
  const [showCreateTaskModal, setShowCreateTaskModal] = React.useState(false)
  const [showGeneratePONumberModal, setShowGeneratePONumberModal] = React.useState(false)
  const [tasks, setTasks] = React.useState(props.tasks || [])
  const [state, setState] = React.useState({
    counter: 0
  })
  const [activeTask, setActiveTask] = React.useState({})
  const [showTaskDetailsModal, setShowTaskDetailsModal] = React.useState(false)
  const [showCreateNewProposalModal, setShowCreateNewProposalModal] = React.useState(false)
  const [userMenuAnchor, setUserMenuAnchor] = React.useState(null);
  const [userMenuOpen, setUserMenuOpen] = React.useState(false)
  const [showGetUserTelephone, setShowGetUserTelephone] = React.useState(false)
  const [isChatDrawerOpen, setIsChatDrawerOpen] = React.useState(false)
  const [unreadMessages, setUnreadMessages] = React.useState(0)

  let logoURL = `${process.env.PUBLIC_URL}/logowide2.png`
  if (props.user && props.user.defaultEntity) {
    if (props.user.defaultEntity && props.user.defaultEntity._id) {
      logoURL = `${process.env.PUBLIC_URL}/${props.user.defaultEntity._id}_Logo.png`
    } else {
      logoURL = `${process.env.PUBLIC_URL}/${props.user.defaultEntity}_Logo.png`
    }
  }
  console.log('Logo URL', logoURL)
  React.useEffect(() => {
    // if (props.tasks && props.tasks.length) setTasks(props.tasks)
    if (props.user && props.user._id) {
      // console.log('Props.user NAVIGATION 251', props)
      setUnreadMessages(props.user.unreadMessages)
      socket.on(`${props.user._id}_tasks`, function (msg) {
        // console.log('GOT A SOCKET MESSAGE TASK: ', msg)
        if (msg && msg.message) {
          props.notification({
              type: 'info',
              title: 'New Message',
              message: msg.message
            })
          if (msg.task && msg.task._id) {
          // console.log('Task: ', msg.task)
            let newTasks = tasks
            // let audio = new Audio('taskNotification.mp3');
            // // audio.play();
            // try {
            //   audio.play()
            // } catch (e) {
            // // console.log('Error playing audio', e)
            // }
            newTasks.unshift(msg.task)
            setActiveTask(msg.task)
            setTasks(newTasks)
            setState({...state, counter: state.counter++})
            props.setTasks(newTasks)
            // setShowTaskDetailsModal(true)
            props.notification({
              type: 'info',
              title: 'New Task Created For You',
              message: msg.task.details
            })
          }
        }
      })
      socket.on(`${props.user._id}_messages`, function (msg) {
        // console.log('Incoming Messsage!!', msg)
        if (msg.data && msg.data.type === 'text') {
          // Add this to our unread messages
          let title = `New Message From ${msg.author.firstName} ${msg.author.lastName}`
          props.notification({
            type: 'info',
            title: title,
            message: msg.data.text
          })
          // Play Notification Sound
          let audio = new Audio('taskNotification.mp3');
          // console.log('Play Audio...', audio)
          audio.play();
          let updatedUser = {...props.user}
          let totalMsgs = props.user.unreadMessages
          totalMsgs++
          updatedUser.unreadMessages = totalMsgs
          props.setUser(updatedUser)
        }
      })
    }
    socket.on('databaseUpdates', (payload) => {
      // console.log('Database Update Coming: ', payload)
      if (payload && payload.clients) {
        // console.log('Add client: ', payload.clients)
        props.setClients(payload.clients)
      }
    })
    return () => {
      if (props.user && props.user._id) {
        socket.off(`${props.user._id}_tasks`)
        socket.off('databaseUpdates')
        socket.off(`${props.user._id}_messages`)
      }
    }
  }, [props])

  React.useEffect(() => {
    if (props.tasks && props.tasks.length) setTasks(props.tasks)
  }, [props.tasks])

  const handlePopoverOpen = (event) => {
  // console.log('enter')
    setAnchorElTasks(event.currentTarget);
  };

  const handlePopoverClose = (task) => {
  // console.log('leave')
    if (task) {
    // console.log('Open this task!', task)
      setActiveTask(task)
      setShowTaskDetailsModal(true)
    }
    setAnchorElTasks(null);
  };

  const tasksOpenpen = Boolean(anchorElTasks);


  const handleMenu = event => {
  // console.log('Profile menu')
    setAnchorEl(event.currentTarget);
    setProfileOpen(true)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setProfileOpen(false)
  };

  const handleRouteChange = (route) => {
  // console.log('change route ', route)
  // console.log(props)
    // let location = useHistory()
    // console.log(history)
    // console.log(location)
    setAnchorEl(null);
    setOpen(false)
    setProfileOpen(false)
    history.push({ pathname: route, props: { stuff: props }});
    // history.push(route);
  }

  const toggleDrawer = (side, openUpdate, num) => event => {
  // console.log('Toggle Drwaer ', num)
    // console.log(openUpdate)
    // Do NOT close if expansion was
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    // console.log('event??', event.target)
    // console.log(event.currentTarget)
    // console.log(event.target.innerHTML)
    if (event.target.innerHTML === 'Settings') {

    } else {
      setOpen( openUpdate )
    }
    // setState({ ...state, [side]: open });
  };

  const [openExpansion, setOpenExpansion] = React.useState(false);

  const handleClick = (e) => {
    e.preventDefault()
    setOpenExpansion(!openExpansion);
  };

  const handleCreateNewEstimate = () => {
    // window.open('/newEstimateProcess', "_blank")
    setShowCreateNewProposalModal(true)
  }

  const toggleChatDrawer = (side, openUpdate, num) => event => {
  // console.log('Toggle Chat ', side)
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
  // console.log(openUpdate)
  // console.log(num)
    setIsChatDrawerOpen(openUpdate)
  }

  const chatDrawer = side => (
    <div
      style={{ width: 500 }}
      role="presentation"
      // onClick={toggleDrawer(side, false, 2)}
      onKeyDown={() => toggleChatDrawer(side, false, 3)}
      >
      Test
    </div>
  )

  let employee = null
  if (props.user && props.employees && props.employees.length) {
    props.employees.forEach(emp => {
      if (emp.user && emp.user === props.user._id) employee = emp
    })
    // console.log('EMPLOIYEE:', employee)
  }

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      // onClick={toggleDrawer(side, false, 2)}
      onKeyDown={toggleDrawer(side, false, 3)}
    >
    
      <div >
      <center>
      { 
        (props.user && props.user.defaultEntity) ? (
              <img alt="Logo" src={`${process.env.PUBLIC_URL}/${(props.user.defaultEntity && props.user.defaultEntity._id) ? props.user.defaultEntity._id : props.user.defaultEntity}_NavLogo.png`} style={{margin: '5px', padding: '5px', maxWidth: 100, maxHeight: 100}} />
                ) : (
                  <img alt="Logo" src={`https://dflportal.com/logo.png`} style={{ marginTop: '5px', maxHeight: 100, maxWidth: 300}}/>
                )
                }
             </center>
      </div>
      <List>
      {/* {props.permissions[0]['view'] ? (
          <ListItem button onClick={() => handleRouteChange('/clients')}>
            <ListItemIcon><PeopleIcon /></ListItemIcon>
            <ListItemText primary="Customers" />
          </ListItem>
        ) : ''} */}
        <ListItem button onClick={() => handleRouteChange('/')}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ProtectedRoute type="Customers" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/clients')}>
            <ListItemIcon><PeopleIcon /></ListItemIcon>
            <ListItemText primary="Customers" />
          </ListItem>
        </ProtectedRoute>
        <ProtectedRoute type="Customers" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/contacts')}>
            <ListItemIcon><PeopleIcon /></ListItemIcon>
            <ListItemText primary="Contacts" />
          </ListItem>
        </ProtectedRoute>
        <ProtectedRoute type="Jobs" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/fieldupdates')}>
            <ListItemIcon><SystemUpdateAltIcon /></ListItemIcon>
            <ListItemText primary="Field Updates" />
          </ListItem>
        </ProtectedRoute>
        <ProtectedRoute type="Customers" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/bhl')}>
            <ListItemIcon><AccountBoxIcon /></ListItemIcon>
            <ListItemText primary="Benhart Dashboard" />
          </ListItem>
        </ProtectedRoute>
        <ProtectedRoute type="Customers" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/hmi')}>
            <ListItemIcon><DashboardIcon /></ListItemIcon>
            <ListItemText primary="HMI Dashboard" />
          </ListItem>
        </ProtectedRoute>
        <ProtectedRoute type="Maintenance Routes" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/dflmtcdashboard')}>
            <ListItemIcon><AccountBoxIcon /></ListItemIcon>
            <ListItemText primary="DFL MTC Dashboard" />
          </ListItem>
        </ProtectedRoute>
        <ProtectedRoute type="Shed Inventory" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/lotinventory')}>
            <ListItemIcon><ShoppingCartIcon /></ListItemIcon>
            <ListItemText primary="Lot Inventory" />
          </ListItem>
        </ProtectedRoute>
        <ProtectedRoute type="Jobs" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/assignWorkorders')}>
            <ListItemIcon><ListIcon /></ListItemIcon>
            <ListItemText primary="Open Jobs" />
          </ListItem>
        </ProtectedRoute>

        
        <ProtectedRoute type="Jobs" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/crewWorkorders')}>
            <ListItemIcon><ListIcon /></ListItemIcon>
            <ListItemText primary="Jobs By Crew" />
          </ListItem>
        </ProtectedRoute>
        <ProtectedRoute type="Rosters" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/roster')}>
            <ListItemIcon><AssignmentIcon /></ListItemIcon>
            <ListItemText primary="Daily Roster" />
          </ListItem>
        </ProtectedRoute>

        <ProtectedRoute type="Timesheets" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/timesheets')}>
            <ListItemIcon><TimeSheetIcon /></ListItemIcon>
            <ListItemText primary="TimeSheets" />
          </ListItem>
        </ProtectedRoute>
        {/* <ProtectedRoute type="Crews" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/crews')}>
              <ListItemIcon><AssignmentIndIcon /></ListItemIcon>
              <ListItemText primary="Crews" />
          </ListItem>
        </ProtectedRoute>
        <ProtectedRoute type="Equipment" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/equipment')}>
            <ListItemIcon><LocalShippingIcon /></ListItemIcon>
            <ListItemText primary="Equipment" />
          </ListItem>
        </ProtectedRoute> */}
        <ProtectedRoute type="Customers" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/projects')}>
            <ListItemIcon><ListIcon /></ListItemIcon>
            <ListItemText primary="Projects" />
          </ListItem>
        </ProtectedRoute>
        {/* <ProtectedRoute type="Forecast" kind="view" perms={props.perms} > */}
          <ListItem button onClick={() => handleRouteChange('/openEstimates')}>
            <ListItemIcon><ListIcon /></ListItemIcon>
            <ListItemText primary="Open Estimates" />
          </ListItem>
        {/* </ProtectedRoute> */}
        {/* <ProtectedRoute type="Employees" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/employees')}>
            <ListItemIcon><NaturePeopleIcon /></ListItemIcon>
            <ListItemText primary="Employees" />
          </ListItem>
        </ProtectedRoute> */}
        {/* <ProtectedRoute type="Users" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/users')}>
            <ListItemIcon><SupervisedUserCircleIcon /></ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </ProtectedRoute> */}
        <ProtectedRoute type="Dashboard" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/dashboard')}>
            <ListItemIcon><DashboardIcon /></ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </ProtectedRoute>
        <ProtectedRoute type="Uploads" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/bulkuploads')}>
            <ListItemIcon><CloudUploadRoundedIcon /></ListItemIcon>
            <ListItemText primary="Uploads" />
          </ListItem>
        </ProtectedRoute>    
        <ProtectedRoute type="Reports" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/reports')}>
            <ListItemIcon><PrintIcon /></ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItem>
        </ProtectedRoute>  
        <ProtectedRoute type="Accounts Recv" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/arfinance')}>
            <ListItemIcon><AccountBalanceWalletIcon /></ListItemIcon>
            <ListItemText primary="Accounts Receivable" />
          </ListItem>
        </ProtectedRoute>
        <ProtectedRoute type="Invoices" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/pending-invoices')}>
            <ListItemIcon><LocalAtmIcon /></ListItemIcon>
            <ListItemText primary="Pending Invoices" />
          </ListItem>
        </ProtectedRoute>
        <ProtectedRoute type="Invoices" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/open-invoices')}>
            <ListItemIcon><InvoiceIcon /></ListItemIcon>
            <ListItemText primary="Open Invoices" />
          </ListItem>
        </ProtectedRoute>
        <ProtectedRoute type="Purchase Orders" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/purchaseorders')}>
            <ListItemIcon><MonetizationOnIcon /></ListItemIcon>
            <ListItemText primary="Purchase Orders" />
          </ListItem>
        </ProtectedRoute>
        <ProtectedRoute type="Customers" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/poolmtcdashboard')}>
            <ListItemIcon><PoolMtcIcon /></ListItemIcon>
          <ListItemText primary="Pool Maintenance" />
          </ListItem>
        </ProtectedRoute>
        
        <ProtectedRoute type="Vendors" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/vendors')}>
            <ListItemIcon><VendorsIcon /></ListItemIcon>
          <ListItemText primary="Vendors" />
          </ListItem>
        </ProtectedRoute>
        <ProtectedRoute type="Customers" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/simulatemobileappview')}>
            <ListItemIcon><PhoneAndroid /></ListItemIcon>
          <ListItemText primary="Mobile App View" />
          </ListItem>
        </ProtectedRoute>
        <ProtectedRoute type="Equipment" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/vehiclemaintenancehistory')}>
            <ListItemIcon><PageviewIcon /></ListItemIcon>
          <ListItemText primary="Vehicle Maintenance" />
          </ListItem>
        </ProtectedRoute>
        <ProtectedRoute type="Equipment" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/vehiclerepairs')}>
            <ListItemIcon><BuildIcon /></ListItemIcon>
          <ListItemText primary="Vehicle Repairs" />
          </ListItem>
        </ProtectedRoute>
        {/* <ProtectedRoute type="Settings" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/services')}>
            <ListItemIcon><BulletIcon /></ListItemIcon>
            <ListItemText primary="Services" />
          </ListItem>
        </ProtectedRoute> */}
        <ProtectedRoute type="Settings" kind="view" perms={props.perms} >
          <ListItem button onClick={handleClick} id="expand">
            <ListItemIcon><SettingsIcon /></ListItemIcon>
          <ListItemText primary="Settings" />
          {openExpansion ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openExpansion} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ProtectedRoute type="Users" kind="view" perms={props.perms} >
              <ListItem button style={{marginLeft: '10px'}} onClick={() => handleRouteChange('/users')}>
                <ListItemIcon><SupervisedUserCircleIcon /></ListItemIcon>
                <ListItemText primary="Users" />
              </ListItem>
            </ProtectedRoute>
            <ProtectedRoute type="Crews" kind="view" perms={props.perms} >
              <ListItem button style={{marginLeft: '10px'}} onClick={() => handleRouteChange('/crews')}>
                  <ListItemIcon><AssignmentIndIcon /></ListItemIcon>
                  <ListItemText primary="Crews" />
              </ListItem>
            </ProtectedRoute>
            <ProtectedRoute type="Employees" kind="view" perms={props.perms} >
              <ListItem button style={{marginLeft: '10px'}} onClick={() => handleRouteChange('/employees')}>
                <ListItemIcon><NaturePeopleIcon /></ListItemIcon>
                <ListItemText primary="Employees" />
              </ListItem>
            </ProtectedRoute>
            <ProtectedRoute type="Equipment" kind="view" perms={props.perms} >
              <ListItem button style={{marginLeft: '10px'}} onClick={() => handleRouteChange('/equipment')}>
                <ListItemIcon><LocalShippingIcon /></ListItemIcon>
                <ListItemText primary="Equipment" />
              </ListItem>
            </ProtectedRoute>
            <ProtectedRoute type="Services" kind="view" perms={props.perms} >
              <ListItem style={{marginLeft: '10px'}} button onClick={() => handleRouteChange('/services')}>
                <ListItemIcon><ListIcon /></ListItemIcon>
              <ListItemText primary="Services" />
              </ListItem>
            </ProtectedRoute>
            {/* <ProtectedRoute type="Shed Inventory" kind="view" perms={props.perms} >
              <ListItem style={{marginLeft: '10px'}} button onClick={() => handleRouteChange('/shedinventory')}>
                <ListItemIcon><ShoppingCartIcon /></ListItemIcon>
                <ListItemText primary="Shed Inventory" />
              </ListItem>
            </ProtectedRoute> */}
            <ProtectedRoute type="Shed Inventory" kind="view" perms={props.perms} >
              <ListItem style={{marginLeft: '10px'}} button onClick={() => handleRouteChange('/takeoffinventory')}>
                <ListItemIcon><ShoppingCartIcon /></ListItemIcon>
                <ListItemText primary="TakeOffs" />
              </ListItem>
            </ProtectedRoute>
            <ProtectedRoute type="Entities" kind="view" perms={props.perms} >
              <ListItem style={{marginLeft: '10px'}} button onClick={() => handleRouteChange('/entities')}>
                <ListItemIcon><LocationCityIcon /></ListItemIcon>
              <ListItemText primary="Entities" />
              </ListItem>
            </ProtectedRoute>
              {/* <ListItem style={{marginLeft: '10px'}} button onClick={() => handleRouteChange('/tax-details')}>
                <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
              <ListItemText primary="Taxes" />
              </ListItem> */}
              <ProtectedRoute type="Settings" kind="view" perms={props.perms} >
              <ListItem style={{marginLeft: '10px'}} button onClick={() => handleRouteChange('/settings')}>
                <ListItemIcon><SettingsApplicationsIcon /></ListItemIcon>
              <ListItemText primary="Configurations" />
              </ListItem>
            </ProtectedRoute>
          </List>
        </Collapse>
        </ProtectedRoute>
        {/* <ProtectedRoute type="Entities" kind="view" perms={props.perms} >
          <ListItem button onClick={() => handleRouteChange('/entities')}>
              <ListItemIcon><EntityIcon /></ListItemIcon>
              <ListItemText primary="Entities" />
          </ListItem>
        </ProtectedRoute> */}
      </List>

    </div>
  );

    const linkColor = props.theme === 'light' ? styles.black : styles.white
    const linkStyle = [styles.link, linkColor].join(' ')
    // console.log('HISTORY::', history)
    let hideAppBar = false
    if (history.location.pathname.includes('estimate/')) hideAppBar = true
    if (history.location.pathname.includes('online/')) hideAppBar = true
    if (history.location.pathname.includes('view/workorder/')) hideAppBar = true
    if (history.location.pathname.includes('updateemployeephone')) hideAppBar = true
    if (history.location.pathname.includes('verifyEmployeePin')) hideAppBar = true
    if (history.location.pathname.includes('adotrepsignaturepage')) hideAppBar = true
    if (history.location.pathname.includes('downloadmobileapp')) hideAppBar = true
    if (history.location.pathname.includes('timeOffRequestForm')) hideAppBar = true
    if (history.location.pathname.includes('view/seasonalflowers')) hideAppBar = true
    // const handleCreateTask = () => {
    //   // alert('h')
    //   setShowCreateTaskModal(true)
    //   setAnchorElTasks(null);
    // }



    const handleViewTasks = () => {
      handlePopoverClose()
      handleRouteChange('/tasks')
    }


    const handlePopOverToggle = (event) => {
    // console.log("toggle")
      if (anchorElTasks) {
        setAnchorElTasks(null);
      } else {
        setAnchorElTasks(event.currentTarget);
      }
      
    };

    const handleNewTaskResponse = (d) => {
      // setAnchorElTasks(null);
      setShowCreateTaskModal(false)
    }

    const handleCloseTaskModal = () => {
      setShowTaskDetailsModal(false)
    }

    const handleViewTaskResponse = (data, type) => {
      // console.log('Data: ', data)
      // console.log('Type: ', type)

      if (type === 'complete') {
        setShowTaskDetailsModal(false)
        let newTasks = tasks
        let index = newTasks.findIndex(item => {
          // console.log(item)
          return (item._id.toString() === data._id.toString()) 
        })
        // console.log(index)
        if (index > -1) {
          newTasks.splice(index, 1)
          // console.log(newTasks)
          setTasks(newTasks)
          setState({...state, counter: state.counter++})
        }
      }
      if (type === 'saved') {
        // update our task
        // console.log('Save this task: ', data)
        // console.log(activeTask)
        let newTasks = tasks
        let index = newTasks.findIndex(item => {
          // console.log(item)
          return (item._id.toString() === data._id.toString()) 
        })
        // console.log(index)
        if (index > -1) {
          newTasks[index] = data // (index, 1)
          // console.log(newTasks)
          setTasks(newTasks)
          setActiveTask(data)
          setState({...state, counter: state.counter++})
        }
      }
    }

  // Open a Modal to Capture PO Information
  const handleOpenPOModal = () => {
    setShowGeneratePONumberModal(true)
    if (props.user && !props.user.telephone) setShowGetUserTelephone(true)
  }

  const handleClosePOModal = () => {
    setShowGeneratePONumberModal(false)
    if (props.user && !props.user.telephone) {
    // console.log('Alert this bitch')
      setShowGetUserTelephone(true)
    }
  }

  const viewEstimate = (d) => {
    setShowCreateNewProposalModal(false)
    history.push(`/newestimateview/${d._id}`, { state: d })
  }

  const closeNewEstimateModal= () => {
    setShowCreateNewProposalModal(false)
  }

  const openUserProfileMenu = (event) => {
    setUserMenuOpen(true);
    setUserMenuAnchor(event.currentTarget);
  };

  const closeUserProfileMenu = () => {
    setUserMenuOpen(false);
    setUserMenuAnchor(null);
  };

  const toggleUserProfileMenu = (event) => {
    if (userMenuOpen) {
      setUserMenuOpen(false);
      setUserMenuAnchor(null);
    } else {
      setUserMenuOpen(true);
      setUserMenuAnchor(event.currentTarget);
    }
  };

  const popoverRef = React.useRef(null)

  const handleBlur = (event) => {
    // Check if the related target is outside the popover
    if (!popoverRef.current.contains(event.relatedTarget)) {
      closeUserProfileMenu();
    }
  };

  const handlePreferences = () => {
    closeUserProfileMenu()
    history.push(`/preferences`)
  }

  const handleProfile = () => {
    closeUserProfileMenu()
    history.push(`/profile`)
  }

  const handleLogOut = () => {
    closeUserProfileMenu()
    history.push(`/logout`)
  }

  const updateUserPhone = (data) => {
    setShowGetUserTelephone(false)
  }

  const handleOpenChatWindow = () => {

  }
  let unreadMessageCount= unreadMessages
  // const linkColor = styles.blue
  // const linkStyle = [styles.link, linkColor].join(' ')
    return (
      <div className={ styles.nav }>
        {
          hideAppBar ? (
            <div className={ styles.flexGrow }>
              
            </div>
          ) : (
            <AppBar position="static" color="white">
            <Toolbar>
              <div className={ classes.flexGrow }>
                
                {
                  props.authenticated ? 
                  (
                    <>
                    {/* <ViewTaskModal open={showTaskDetailsModal} task={activeTask} closeModal={handleCloseTaskModal} updateParent={handleViewTaskResponse} /> */}
                    <CreateNewEstimateModal open={showCreateNewProposalModal} addys={[]} viewEstimate={viewEstimate} closeModal={closeNewEstimateModal } />
                    <CreateTaskModal open={showCreateTaskModal} updateParent={handleNewTaskResponse} closeModal={handleNewTaskResponse} />
                    <ViewTaskModal open={showTaskDetailsModal} task={activeTask} closeModal={handleCloseTaskModal} updateParent={handleViewTaskResponse} />
                    <CreatePOModal open={showGeneratePONumberModal} closeModal={handleClosePOModal} />
                    <GatherUserPhoneNumberModal open={showGetUserTelephone} closeModal={updateUserPhone} user={props.user} url={props.url} />
                    <font>
                  <IconButton onClick={toggleDrawer('left', !open, 1)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                    <MenuIcon  />
                    <SwipeableDrawer
                        open={open}
                        onClose={toggleDrawer('left', false, 4)}
                        onOpen={toggleDrawer('left', true, 5)}
                      >
                        {sideList('left')}
                      </SwipeableDrawer>
                  </IconButton>
                 
                    
                    {/* <Link className={ linkStyle } to="/profile">Profile</Link> */}
                    {/* <Link className={ linkStyle } to="/">Home</Link>
                    <Link className={ linkStyle } to="/clients">Customers</Link> */}
                    
                    {/* <Link className={ linkStyle } to="/statements">Statements</Link> */}
                  </font> 
                  
                  <Tooltip title="View Tasks" >
                      <IconButton onClick={handlePopOverToggle}aria-label="view" color="primary"
                          
                          >
                            <Badge badgeContent={tasks.length ? tasks.length : '0'} color="primary">
                              <NotificationImportantIcon />
                            </Badge>
                          
                          
                            
                            
                            </IconButton>
                   </Tooltip>
                            <Popover
                              id="mouse-over-popover"
                              className={classes.popover}
                              classes={{
                                paper: classes.paper,
                              }}
                              open={tasksOpenpen}
                              anchorEl={anchorElTasks}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              onClose={handlePopoverClose}
                              // onMouseLeave={handlePopoverClose}
                              disableRestoreFocus
                              // disableEnforceFocus
                              // PaperProps={{onMouseEnter: handlePopoverOpen, onMouseLeave: handlePopoverClose}}
                            >
                              <TaskSummary tasks={tasks} handlePopoverClose={handlePopoverClose} handleViewTaskResponse={handleViewTaskResponse} handleRouteChange={handleRouteChange} />
                            </Popover>
                            {/* <ProtectedButton type="Purchase Orders" kind="update" perms={props.perms}> */}
                              <Tooltip title="Create Purchase Request" style={{ marginLeft: 35 }}>
                                <IconButton onClick={handleOpenPOModal} aria-label="Generate PO" color="primary" >
                                  <LocalAtmIcon />
                                </IconButton>
                              </Tooltip>
                            {/* </ProtectedButton> */}
                            {/* <ProtectedButton type="Estimates" kind="update" perms={props.perms}> */}
                              <Tooltip title="Create Estimate" style={{ marginLeft: 35 }}>
                                <IconButton onClick={handleCreateNewEstimate} aria-label="Create Estimate" color="primary" >
                                  <AssignmentIcon />
                                </IconButton>
                              </Tooltip>
                            {/* </ProtectedButton> */}
                            <Tooltip title="View Chat" style={{ marginLeft: 35 }}>
                              <IconButton onClick={toggleChatDrawer('right', true, 25)}aria-label="view" color="primary"
                                  
                                  >
                                    <Badge badgeContent={unreadMessageCount} color="secondary">
                                      <ChatIcon />
                                    </Badge>
                                  
                                    </IconButton>
                                    
                          </Tooltip>
                            
     
                            <ChatDrawer 
                              open={isChatDrawerOpen}
                              room={'All'}
                              onClose={toggleChatDrawer('right', false, 4)} 
                            />
                    </>
                  ) :
                  (
                    <font>
                    <Link className={ linkStyle } to="/login">Login</Link>
                    <Link className={ linkStyle } to="/recovery">Password Reset</Link>
                    {/* <Link className={ linkStyle } to="/registration">Request Access</Link> */}
                  </font>
                  )
                  
                }
              </div>
              <div className={classes.logoArea}>
                <img alt="Logo" src={`${logoURL}`} style={{ marginLeft: '-100px', marginTop: '5px', maxHeight: 100, maxWidth: 300}}/>
              </div>

              {
                  props.authenticated ? 
                <div
                  // aria-owns={tasksOpenpen ? 'mouse-over-popover' : undefined}
                  // aria-haspopup="true"
                  // onMouseEnter={openUserProfileMenu}
                  // onMouseLeave={handleBlur}
                  style={{ cursor: 'pointer' }}
                  // onClick={toggleUserProfileMenu}
                  // onBlur={handleBlur}
                >
                    <Tooltip title={`${props.user ? props.user.name : 'User Options'} Options`}>
                      <IconButton onClick={toggleUserProfileMenu} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <UserAvatar user={props.user} employee={employee} />                        
                    </IconButton>
                  </Tooltip>
                    <Menu
                      id="menu-appbar"
                      anchorEl={userMenuAnchor}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={userMenuOpen}
                      onClose={closeUserProfileMenu}
                    >
                      {/* <MenuItem ><Link className={ linkStyle } styles={{ marginLeft: -10 }} to="/myqueue">My Queue</Link></MenuItem> */}
                      <MenuItem onClick={handleProfile}>Profile</MenuItem>
                      <MenuItem onClick={toggleUserProfileMenu}><Link style={{textDecoration: 'none', color: 'inherit' }} to="/myqueue">My Queue</Link></MenuItem>
                      <MenuItem onClick={toggleUserProfileMenu}><Link style={{textDecoration: 'none', color: 'inherit' }} to="/myestimates">My Estimates</Link></MenuItem>
                      <MenuItem onClick={toggleUserProfileMenu}><Link style={{textDecoration: 'none', color: 'inherit' }} to="/mypurchaserequests">My POs</Link></MenuItem>
                      {/* <MenuItem onClick={handlePreferences}>Preferences</MenuItem> */}
                      <MenuItem onClick={handleLogOut} style={{ marginTop: 25 }}>Sign Out</MenuItem>
                    </Menu>
                  </div>
                  : ''
              }
            </Toolbar>
          </AppBar>
          )
        }
      </div>
    )
}

const UserAvatar = ({ user, employee }) => {
  let imgsrc = `${process.env.PUBLIC_URL}/logo.png`
  if (employee && employee.profile_photo_url) {
    imgsrc = employee.profile_photo_url
    if (!imgsrc.includes('https')) imgsrc = `https://${employee.profile_photo_url}`
  }
  // console.log('user avatar', user)
  // console.log('1009', employee)
  // console.log('img src', imgsrc)
  let initials = 'DFL'
  let name = 'Options'
  if (user && user._id) {
    name = user.name
    let names = user.name.split(' ')
    // console.log('names')
    if (names && names.length > 1) {
      initials = ''
      names.map(item => {
        // console.log('Item: %s - Initials: ', item, initials)
        initials += `${item.substring(0, 1)}`
      })
    } else {
      initials = `${user.name.substring(0, 1)}`
    }
  }
  // console.log('Initials', initials)
  return (
    <Avatar
     alt={name}
     src={imgsrc}
     imgProps={{
      onError: (e) => {
        e.target.onerror = null // prevents looping
        e.target.src = `${process.env.PUBLIC_URL}/logo.png` // default image or make it blank to show initials
      }
    }}
    >{initials}</Avatar>
  )
}

const RightSideMenu = (props) => {
  const linkColor = styles.blue
  const linkStyle = [styles.link, linkColor].join(' ')
  return (
    <div style={{ padding: 5, border: '2px solid #444', overflow: 'hidden'}}>
      <Grid container spacing={1} style={{ textAlign: 'center' }}>
        <Grid item lg={12} md={12} sm={12} xs={12} style={{ fontSize: '1.2em', color: '#bbb', fontWeight: 555}}>Options</Grid>  
        <Grid item lg={12} md={12} sm={12} xs={12}><Link className={ linkStyle } to="/profile">My Profile</Link></Grid>  
        <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: 25 }}><Link className={ linkStyle } to="/logout">Sign out</Link></Grid>  
      </Grid>
    </div>
  )
}

const GatherUserPhoneNumberModal = (props) => {
  const [state, setState] = React.useState({
    telephone: ''
  })
  const handleChange = (e) => {
    setState({...state, telephone: e.target.value })
  }

  const handleSave = async () => {
  // console.log('Save:', state.telephone)
    let user = props.user
    user.telephone = state.telephone
  // console.log('User to save:', user)
    const response = await axios.post(`${props.url}/api/user/profileupdate`, user)
  // console.log('Response:', response.data)
    props.closeModal(state.telephone)
  }

  const handleClose = () => {
    props.closeModal()
  }
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.open}
      >
        <DialogTitle>How Can We Update You?</DialogTitle>
        <DialogContent>
          <DialogContentText>Please Provide A Telephone Number Where You Can Receive SMS Updates About This Purchase Request</DialogContentText>
          <InputMask name="telephone" mask="(999) 999-9999" maskPlaceholder=" " onChange={handleChange}  value={state.telephone }>
              {
                (inputProps) => {
                  return (
                  <TextField                        
                    margin="dense"
                    id="Telephone"
                    label={"Telephone Number #"}
                    type="telephone"
                    fullWidth
                    {...inputProps}
                />
                  )
                }
              }
          </InputMask>
            
        
        </DialogContent>
        <DialogActions>
        <Button
            onClick={handleClose}
            color="primary"
          >
            No Thanks
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    theme: state.theme,
    perms: state.perms,
    user: state.user,
    notification: state.notification,
    tasks: state.tasks,
    url: state.url,
    employees: state.employees
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setTheme: theme => { dispatch(setTheme(theme)) },
    setTasks: (tasks) => { dispatch(setTasks(tasks)) },
    setClients: (clients) => { dispatch(setClients(clients)) },
    setUser: (user) => { dispatch(setUser(user)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
