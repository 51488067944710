import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Checkbox,
  FormControlLabel,
  Paper,
  Grid,
  Typography,
  makeStyles,
  Divider,
  Tooltip
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Edit as EditIcon,
  Cancel as CancelIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@material-ui/icons';
import { currencyFormat, handleFocus, unitsOfMeasureAll } from '../../services/common'
import { Autocomplete } from '@material-ui/lab';
// import TaxModule from './Taxes/TaxModule'

const determineUnit = (item) => {
  console.log('What is our unit"".,', item)
  let unit = item?.size ? item.size : item?.unit || 'Each'
  return unit
}

const laborUnits = [
  { value: 'days', label: 'Days' },
  { value: 'hours', label: 'Hours' }
];

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '90vw',
      maxWidth: '90vw',
    },
  },
  tableContainer: {
    marginBottom: theme.spacing(3),
    maxHeight: '50vh',
    overflow: 'auto',
  },
  editForm: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(1),
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  total: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  taxes: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  totalText: {
    marginRight: theme.spacing(2),
  },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer',
    },
  },
  selectedRow: {
    backgroundColor: theme.palette.action.selected,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  dialog: {
    '& .MuiDialog-paper': {
      width: '90vw',
      maxWidth: '90vw',
    },
  },
  formSection: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  formTitle: {
    marginBottom: theme.spacing(3),
  },
  fieldGroup: {
    marginBottom: theme.spacing(3),
  },
  fieldSet: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  fieldSetTitle: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    marginBottom: theme.spacing(3),
    maxHeight: '40vh',
    overflow: 'auto',
  },
  infoText: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    marginTop: theme.spacing(1),
  },
  total: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  totalText: {
    marginRight: theme.spacing(2),
  },
  internalNotesAreaDetails: {
    fontWeight: 500,
    color: 'red',
    fontSize: '0.9em'
  },
  visibilityIcon: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&.inactive': {
      color: theme.palette.text.disabled,
    },
  },
}));

const formatLabel = (camelCase) => {
  return camelCase
    .replace(/([A-Z])/g, ' $1') // Add space before capital letters
    .replace(/^./, str => str?.toUpperCase()); // Capitalize first letter
};

// Define line item types - these could be moved to a constants file
const lineItemTypes = ['Labor', 'Materials', 'Equipment', 'Notes', 'Measurements', 'Fee'];

const feeTypes = [
  { value: 'Dumping', label: 'Dumping' },
  { value: 'Hauling', label: 'Hauling' },
  { value: 'Other', label: 'Other' }
];

const LineItemBuilder = ({ 
  open, 
  onClose, 
  onSubmit, 
  initialLineItems = [],
  client,
  entities = [],
  url,
  takeOffList,
  isForSubLineItems
}) => {
  // console.log('Line item builder client', client)
  // console.log('Entities', entities)
  const classes = useStyles();
  const [lineItems, setLineItems] = useState(initialLineItems);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState('new'); // 'new' or 'edit'
  const [editingItem, setEditingItem] = useState({
    qty: '',
    type: '',
    description: '',
    costPer: '0',
    pricePer: '',
    taxable: false,
    laborType: '',
    feeType: '',
    equipmentType: '',
    laborUnit: 'days',
    displayOnPdf: false
  });
  // const [takeOffList, setTakeOffList] = useState([])
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [materialOptions, setMaterialOptions] = useState([]);
  // console.log('Got our takeoffs', takeOffList)
  // Reset component when initialLineItems changes
  useEffect(() => {
    console.log('Initial line items changed', initialLineItems)
    if (lineItems !== initialLineItems) {
      setLineItems(initialLineItems);
    }
    setSelectedItem(null);
    setEditMode('new');
    resetEditingItem();
  }, [initialLineItems]);

  const resetEditingItem = () => {
    setEditingItem({
      qty: '',
      type: '',
      description: '',
      costPer: '',
      pricePer: '',
      taxable: false,
      laborType: '',
      equipmentType: '',
      laborUnit: 'days',
      reset: true,
      displayOnPdf: false
    });
  };

  // Add handler for labor unit changes
  const handleLaborUnitChange = (e) => {
    const newUnit = e.target.value;
    const currentQty = parseFloat(editingItem.qty) || 0;
    const crewSize = editingItem.crewSize || 1;
    
    // Convert quantity if changing between days and hours
    let newQty = currentQty;
    if (editingItem.laborUnit === 'days' && newUnit === 'hours') {
      newQty = currentQty * 8; // Convert days to hours
    } else if (editingItem.laborUnit === 'hours' && newUnit === 'days') {
      newQty = currentQty / 8; // Convert hours to days
    }

    setEditingItem(prev => ({
      ...prev,
      laborUnit: newUnit,
      qty: newQty.toString(),
      totalHours: calculateTotalHours(newQty, crewSize, newUnit)
    }));
  };

  const calculateTotalHours = (qty, crewSize, unit) => {
    if (unit === 'days') {
      return qty * crewSize * 8;
    }
    return qty * crewSize; // If unit is hours, just multiply by crew size
  };

  // Update handleQuantityChange to use laborUnit
  const handleQuantityChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    const crewSize = editingItem.crewSize || 1;
    
    setEditingItem(prev => ({
      ...prev,
      qty: e.target.value,
      totalHours: calculateTotalHours(value, crewSize, prev.laborUnit)
    }));
  };

  // Update calculateTotal for Labor items
  const calculateTotal = (item) => {
    if (item.type === 'Notes' || item.type === 'Measurements') {
      return 0;
    }
    
    if (item.type === 'Labor') {
      if (item.laborUnit === 'days') {
        const totalHours = (parseFloat(item.qty) || 0) * (item.crewSize || 1) * 8;
        return totalHours * (parseFloat(item.pricePer) || 0);
      } else { // hours
        const totalHours = (parseFloat(item.qty) || 0) * (item.crewSize || 1);
        return totalHours * (parseFloat(item.pricePer) || 0);
      }
    // } 
    // else if (item.type === 'Equipment') {
    //   const totalHours = (parseFloat(item.qty) || 0) * (item.crewSize || 1) * 8;
    //   return totalHours * (parseFloat(item.pricePer) || 0);
    } else {
      return (parseFloat(item.qty) || 0) * (parseFloat(item.pricePer) || 0);
    }
  };

  // Get appropriate rate based on type and client settings
  const getRate = (type, rateType) => {
    let baseRate = 0;
    console.log('Get rate %s   --- %s ', type, rateType)
    // Find matching entity
    const entityDefaults = entities.find(e => e._id === client?.entity);
    
    if (type === 'Labor') {
      // Get default from entity
      if (entityDefaults?.laborRates?.[rateType]) {
        baseRate = entityDefaults.laborRates[rateType].rate;
      }
      // Override with client specific if exists
      if (client?.laborRates?.[rateType]?.rate) {
        console.log('Use client rate to override this: ', baseRate)
        baseRate = client.laborRates[rateType].rate;
        console.log('To this', baseRate)
      }
    }
    
    if (type === 'Equipment') {
      // Get default from entity
      if (entityDefaults?.equipmentRates?.[rateType]) {
        baseRate = entityDefaults.equipmentRates[rateType].rate;
      }
      // Override with client specific if exists
      if (client?.equipmentRates?.[rateType]?.rate) {
        baseRate = client.equipmentRates[rateType].rate;
      }
    }
    console.log('Base Rate', baseRate)
    return baseRate;
  };

  const getCrewSize = (type, rateType) => {
    const entityDefaults = entities.find(e => e._id === client?.entity);
    
    if (type === 'Labor') {
      return entityDefaults?.laborRates?.[rateType]?.defaultCrewSize || 1;
    }
    
    if (type === 'Equipment') {
      return entityDefaults?.equipmentRates?.[rateType]?.defaultCrewSize || 1;
    }
    
    return 1;
  };

  const handleMaterialChange = (event, newValue) => {
    setSelectedMaterial(newValue);
    console.log('Material Change', newValue)
    if (newValue && newValue.options) {
      // let cleanOptions = newValue.options.map(opt => {
      //   let cleanOpt = 
      // })
      setMaterialOptions(newValue.options);
      setEditingItem(prev => ({
        ...prev,
        materialId: newValue._id,
        description: newValue.description || newValue.name
      }));
    } else {
      setMaterialOptions([]);
      setEditingItem(prev => ({
        ...prev,
        materialId: null,
        optionId: null,
        description: ''
      }));
    }
  };

  const handleOptionChange = (e) => {
    const selectedOption = materialOptions.find(opt => opt._id === e.target.value);
    if (selectedOption) {
      const costPer = parseFloat(selectedOption.price) || 0;
      const pricePer = parseFloat(selectedOption.msrp) || (costPer * 1.4); // Use MSRP or markup

      setEditingItem(prev => ({
        ...prev,
        optionId: selectedOption._id,
        size: selectedOption.size,
        costPer,
        pricePer,
        description: `${selectedMaterial.name} - ${selectedOption.name || selectedOption.size}`
      }));
    }
  };

  const handleEditItem = (item) => {
    console.log('Edit this item', item)
    if (item?.materialId) {
      console.log('Find our material...', item.materialId)
      let mats = takeOffList.find(to => (to._id === item.materialId))
      if (mats?._id) {
        setSelectedMaterial(mats)
      }
    }
    if (item?.optionId) {
      console.log('Find our matching  option...')
    }
    setSelectedItem(item);
    setEditingItem(item);
    setEditMode('edit');
  };

  const handleAddNew = () => {
    setSelectedItem(null);
    resetEditingItem();
    setEditMode('new');
  };

  const handleSave = () => {
    if (editMode === 'new') {
      setLineItems([...lineItems, editingItem]);
    } else {
      setLineItems(lineItems.map(item => 
        item === selectedItem ? editingItem : item
      ));
    }
    setSelectedItem(null);
    resetEditingItem();
    setEditMode('new');
  };

  const handleDelete = (itemToDelete) => {
    setLineItems(lineItems.filter(item => item !== itemToDelete));
    if (selectedItem === itemToDelete) {
      setSelectedItem(null);
      resetEditingItem();
      setEditMode('new');
    }
  };

  // const handleTypeChange = (e) => {
  //   const newType = e.target.value;
  //   setEditingItem(prev => {
  //     const updated = { 
  //       ...prev, 
  //       type: newType,
  //       laborType: '',
  //       equipmentType: '',
  //       costPer: '',
  //       pricePer: '',
  //     };

  //     // Reset specialized fields when type changes
  //     if (newType === 'Labor') {
  //       updated.laborType = '';
  //     } else if (newType === 'Equipment') {
  //       updated.equipmentType = '';
  //     }

  //     return updated;
  //   });
  // };

  const handleTypeChange = (e) => {
    const newType = e.target.value;
    setEditingItem(prev => {
      const updated = { 
        ...prev, 
        type: newType,
        laborType: '',
        equipmentType: '',
        materialId: null,
        optionId: null,
        costPer: '',
        pricePer: '',
        displayOnPdf: false,
        // laborUnits
      };

      if (newType === 'Materials') {
        setSelectedMaterial(null);
        setMaterialOptions([]);
      }

      return updated;
    });
  }

  const handleRateTypeChange = (e) => {
    const rateType = e.target.value;
    const type = editingItem.type;
    const costRate = getRate(type, rateType);
    const crewSize = getCrewSize(type, rateType);
    
    setEditingItem(prev => ({
      ...prev,
      [type === 'Labor' ? 'laborType' : 'equipmentType']: rateType,
      costPer: costRate,
      pricePer: costRate * 1.4, // Default markup, could be configurable
      crewSize: crewSize,
      totalHours: crewSize * (prev.qty || 0) * 8 // Calculate total hours based on days
    }));
  };

  const handleSaveLineItems = () => {
    console.log('Handle Save line items..', lineItems)
    let newItems = []
    lineItems.forEach(item => {
      let itemWTotal = {
        ...item,
        totalPrice: calculateTotal(item),
        lineItemType: item.type,
        unit: determineUnit(item)
      }
      newItems.push(itemWTotal)
    })
    console.log('Save these', newItems)
    onSubmit(newItems)
  }
  
  // const handleQuantityChange = (e) => {
  //   const days = parseFloat(e.target.value) || 0;
  //   const crewSize = editingItem.crewSize || 1;
    
  //   setEditingItem(prev => ({
  //     ...prev,
  //     qty: e.target.value,
  //     totalHours: days * crewSize * 8
  //   }));
  // };

  // const calculateTotal = (item) => {
  //   if (item.type === 'Notes' || item.type === 'Measurements') {
  //     return 0; // Notes and Measurements don't contribute to total
  //   }
    
  //   if (item.type === 'Labor' || item.type === 'Equipment') {
  //     const totalHours = (parseFloat(item.qty) || 0) * (item.crewSize || 1) * 8;
  //     return totalHours * (parseFloat(item.pricePer) || 0);
  //   } else {
  //     return (parseFloat(item.qty) || 0) * (parseFloat(item.pricePer) || 0);
  //   }
  // };

  const calculateGrandTotal = () => {
    return lineItems.reduce((sum, item) => sum + calculateTotal(item), 0);
  };

  const renderFeeForm = () => (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <div className={classes.fieldSet}>
          <Typography variant="subtitle2" className={classes.fieldSetTitle}>
            Fee Details
          </Typography>
          <TextField
            select
            label="Fee Type"
            value={editingItem.feeType || ''}
            onChange={(e) => setEditingItem({ ...editingItem, feeType: e.target.value })}
            fullWidth
            variant="outlined"
            margin="normal"
          >
            {feeTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Amount"
            type="number"
            value={editingItem.amount || ''}
            onChange={(e) => setEditingItem({ 
              ...editingItem, 
              amount: e.target.value,
              pricePer: e.target.value, // Set pricePer to maintain compatibility with total calculation
              qty: '1' // Set qty to 1 for fee items
            })}
            fullWidth
            onFocus={handleFocus}
            variant="outlined"
            margin="normal"
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Display Details"
          value={editingItem.description}
          onChange={(e) => setEditingItem({ ...editingItem, description: e.target.value })}
          fullWidth
          multiline
          rows={2}
          onFocus={handleFocus}
          variant="outlined"
        />
        <TextField
          label="Internal Notes"
          value={editingItem.internalNotes}
          onChange={(e) => setEditingItem({ ...editingItem, internalNotes: e.target.value })}
          fullWidth
          multiline
          style={{ marginTop: 10 }}
          rows={3}
          onFocus={handleFocus}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );

  const isItemValid = (item) => {
    // For Notes and Measurements, only require type and description
    if (item.type === 'Notes' || item.type === 'Measurements') {
      return item.type && item.description;
    }

    if (item.type === 'Fee') {
      return item.type && item.feeType && item.amount && item.description;
    }
    // For other types, keep existing validation
    return (
      item.qty &&
      item.type &&
      item.description &&
      // item.costPer &&
      item.pricePer &&
      ((item.type === 'Labor' && item.laborType) ||
       (item.type === 'Equipment' && item.equipmentType) ||
       (item.type !== 'Labor' && item.type !== 'Equipment'))
    );
  };

  let theEntity = entities.find(ent => (ent._id === client?.entity))
  // console.log('Entity', theEntity)
  let laborRates = client?.laborRates || theEntity?.laborRates
  // console.log('Labor rates', laborRates)
  let equipmentRates = client?.equipmentRates || theEntity?.equipmentRates

  const renderMaterialsForm = () => (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <div className={classes.fieldSet}>
          <Typography variant="subtitle2" className={classes.fieldSetTitle}>
            Material Selection
          </Typography>
          <Autocomplete
            options={takeOffList || []}
            getOptionLabel={(option) => option?.name || ''}
            value={selectedMaterial}
            onChange={handleMaterialChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Material"
                fullWidth
                variant="outlined"
              />
            )}
          />
          {materialOptions.length > 0 && (
            <TextField
              select
              label="Size/Option"
              value={editingItem.optionId || ''}
              onChange={handleOptionChange}
              fullWidth
              variant="outlined"
              margin="normal"
            >
              {materialOptions.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.name || option.size}
                  {option.msrp ? ` - $${option.msrp}` : ''}
                </MenuItem>
              ))}
            </TextField>
          )
          
          }
        </div>
      </Grid>

      <Grid item xs={12} md={4}>
        <div className={classes.fieldSet}>
          <Typography variant="subtitle2" className={classes.fieldSetTitle}>
            Quantity
          </Typography>
          <TextField
            label="Quantity"
            type="number"
            onFocus={handleFocus}
            value={editingItem.qty}
            onChange={(e) => setEditingItem({ ...editingItem, qty: e.target.value })}
            fullWidth
            variant="outlined"
          />
          {materialOptions.length > 0 ? null : (
          <TextField
            select
            label="Unit"
            value={editingItem.unit || ''}
            onChange={(e) => setEditingItem({ ...editingItem, unit: e.target.value })}
            fullWidth
            variant="outlined"
            margin="normal"
          >
            {unitsOfMeasureAll.map((unit) => (
              <MenuItem key={unit.value} value={unit.value}>
                {unit.label}
              </MenuItem>
            ))}
          </TextField>
          )
          } 
         
        </div>
      </Grid>

      <Grid item xs={12} md={4}>
        <div className={classes.fieldSet}>
          <Typography variant="subtitle2" className={classes.fieldSetTitle}>
            Pricing
          </Typography>
          <TextField
            label="Cost Per Unit"
            type="number"
            onFocus={handleFocus}
            value={editingItem.costPer}
            onChange={(e) => setEditingItem({ ...editingItem, costPer: e.target.value })}
            fullWidth
            variant="outlined"
          />
          <TextField
            label="Price Per Unit"
            type="number"
            onFocus={handleFocus}
            value={editingItem.pricePer}
            onChange={(e) => setEditingItem({ ...editingItem, pricePer: e.target.value })}
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={'Display Details'}
          value={editingItem.description}
          onChange={(e) => setEditingItem({ ...editingItem, description: e.target.value })}
          fullWidth
          onFocus={handleFocus}
          multiline
          rows={2}
          variant="outlined"
        />
          <TextField
              label={'Internal Notes'}
              value={editingItem.internalNotes}
              onChange={(e) => setEditingItem({ ...editingItem, internalNotes: e.target.value })}
              fullWidth
              multiline
              style={{ marginTop: 10 }}
              rows={3}
              onFocus={handleFocus}
              variant="outlined"
            />
      </Grid>
    </Grid>
  );

  const renderLaborEquipmentForm = () => {
    const rateTypes = editingItem.type === 'Labor' ? laborRates : equipmentRates;
    let laborOptions = []
    Object.entries(rateTypes).forEach(([key, rate]) => {
      // console.log('Key', key)
      // console.log('Rate', rate)
      if (key === '_id') return null
      let cleanOpt = {
        name: key,
        ...rate
      }
      // console.log("Clean OPT:', ", cleanOpt)
      // return cleanOpt
      laborOptions.push(cleanOpt)
    })
    const subContractorOption = {
      rate: 0,
      defaultCrewSize: 1,
      name: 'subContractor'
    }
    laborOptions.push(subContractorOption)
    // console.log("Labor options", laborOptions)
    return (
      <Grid container spacing={3}>
         <Grid item xs={12} md={4}>
          <div className={classes.fieldSet}>
            <Typography variant="subtitle2" className={classes.fieldSetTitle}>
              {editingItem.type} Details
            </Typography>
            <TextField
              select
              label={`${editingItem.type} Type`}
              value={editingItem.type === 'Labor' ? editingItem.laborType : editingItem.equipmentType}
              onChange={handleRateTypeChange}
              fullWidth
              variant="outlined"
              >
                 {/* {rateTypes && Object.entries(rateTypes).map(([key, rate]) => (
                   */}
                   {
                    laborOptions.map((opt, index) => {
                      // console.log('labor option', opt)
                      return (
                        <MenuItem key={index} value={opt.name}>
                        {formatLabel(opt.name)}
                      </MenuItem>
                      )
                    })
               
              }
            </TextField>
            {editingItem.type === 'Labor' && (
              <TextField
                select
                label="Time Unit"
                value={editingItem.laborUnit}
                onChange={handleLaborUnitChange}
                fullWidth
                variant="outlined"
                margin="normal"
              >
                {laborUnits.map(unit => (
                  <MenuItem key={unit.value} value={unit.value}>
                    {unit.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <TextField
              label={`Number of ${editingItem.type === 'Labor' ? 
                (editingItem.laborUnit === 'days' ? 'Days' : 'Hours') : 
                'Days'}`}
              type="number"
              inputProps={{ step: editingItem.type === 'Labor' && editingItem.laborUnit === 'hours' ? "1" : "0.25" }}
              value={editingItem.qty}
              onChange={handleQuantityChange}
              fullWidth
              onFocus={handleFocus}
              variant="outlined"
              margin="normal"
            />
          </div>
        </Grid>

        <Grid item xs={12} md={4}>
          <div className={classes.fieldSet}>
            <Typography variant="subtitle2" className={classes.fieldSetTitle}>
              Rates
            </Typography>
            <TextField
              label={`Cost Per ${editingItem.type === 'Labor' ? 'Hour' : 'Day'}`}
              type="number"
              onFocus={handleFocus}
              value={editingItem.costPer}
              onChange={(e) => setEditingItem({ ...editingItem, costPer: e.target.value })}
              fullWidth
              variant="outlined"
            />
            <TextField
              label={`Price Per ${editingItem.type === 'Labor' ? 'Hour' : 'Day'}`}
              type="number"
              onFocus={handleFocus}
              value={editingItem.pricePer}
              onChange={(e) => setEditingItem({ ...editingItem, pricePer: e.target.value })}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </div>
        </Grid>

        <Grid item xs={12} md={4}>
          {
            (editingItem?.type === 'Labor') && 
        
          <div className={classes.fieldSet}>
            <Typography variant="subtitle2" className={classes.fieldSetTitle}>
              Crew Information
            </Typography>
            <TextField
              label="Crew Size"
              type="number"
              value={editingItem.crewSize || 1}
              onChange={(e) => setEditingItem({ 
                ...editingItem, 
                crewSize: e.target.value,
                totalHours: calculateTotalHours(
                  parseFloat(editingItem.qty) || 0,
                  parseFloat(e.target.value) || 1,
                  editingItem.laborUnit
                )
              })}
              onFocus={handleFocus}
              fullWidth
              variant="outlined"
            />
            <TextField
              label="Total Hours"
              type="number"
              value={editingItem.totalHours || 0}
              disabled
              fullWidth
              onFocus={handleFocus}
              variant="outlined"
              margin="normal"
            />
          </div>
        }
        </Grid>
        <Grid item xs={12} md={12}>
          <div className={classes.fieldSet}>
            <TextField
              label={'Display Details'}
              value={editingItem.description}
              onChange={(e) => setEditingItem({ ...editingItem, description: e.target.value })}
              fullWidth
              multiline
              rows={2}
              onFocus={handleFocus}
              variant="outlined"
            />
             <TextField
              label={'Internal Notes'}
              value={editingItem.internalNotes}
              onChange={(e) => setEditingItem({ ...editingItem, internalNotes: e.target.value })}
              fullWidth
              multiline
              style={{ marginTop: 10 }}
              rows={3}
              onFocus={handleFocus}
              variant="outlined"
            />
          </div>
        </Grid>
      </Grid>
    );
  };

  const renderNotesMeasurementsForm = (type) => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          label={'Display Details'}
          value={editingItem.description}
          onChange={(e) => setEditingItem({ ...editingItem, description: e.target.value })}
          fullWidth
          multiline
          minRows={4}
          onFocus={handleFocus}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={'Internal Notes'}
          value={editingItem.internalNotes}
          onChange={(e) => setEditingItem({ ...editingItem, internalNotes: e.target.value })}
          fullWidth
          multiline
          minRows={3}
          onFocus={handleFocus}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );

  
  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="xl" 
      fullWidth 
      className={classes.dialog}
    >
      <DialogTitle>Line Item Builder</DialogTitle>
      <DialogContent>
        <Paper className={classes.tableContainer}>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Cost Per</TableCell>
                  <TableCell align="right">Price Per</TableCell>
                  <TableCell align="right">Total</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lineItems.map((item, index) => (
                  <TableRow 
                    key={index}
                    className={`${classes.tableRow} ${selectedItem === item ? classes.selectedRow : ''}`}
                    onClick={() => handleEditItem(item)}
                  >
                    <TableCell>{item.type}</TableCell>
                    <TableCell><DescriptionArea lineItem={item} /></TableCell>
                    <TableCell align="right">
                      {item.type === 'Labor' 
                        ? `${item.qty} ${item.laborUnit} (${item.crewSize || 1} person crew)`
                        : item.type === 'Equipment'
                        ? `${item.qty} days (${item.crewSize || 1} person crew)`
                        : `${item.qty} ${item.unit || ''}`}
                    </TableCell>
                    <TableCell align="right">
                      {
                        (item.type !== 'Notes' && item.type !== 'Measurements') && <>
                         ${parseFloat(item.costPer || 0).toFixed(2)}
                          {item.type === 'Labor' && '/hr'}
                          {item.type === 'Equipment' && '/day'}
                          </>
                      }
                     
                    </TableCell>
                    <TableCell align="right">
                    {
                        (item.type !== 'Notes' && item.type !== 'Measurements') && <>
                      ${parseFloat(item.pricePer).toFixed(2)}
                      {item.type === 'Labor' && '/hr'}
                      {item.type === 'Equipment' && '/day'}
                      </>
                    }
                    </TableCell>
                    <TableCell align="right">
                      {
                          (item.type !== 'Notes' && item.type !== 'Measurements') && <>${calculateTotal(item).toFixed(2)}</>
                      }
                    </TableCell>
                   
                    <TableCell>
                    <Tooltip title={item.displayOnPdf !== false ? "Visible on PDF" : "Hidden on PDF"}>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setLineItems(lineItems.map(li => 
                              li === item ? { ...li, displayOnPdf: !li.displayOnPdf } : li
                            ));
                          }}
                        >
                          {item.displayOnPdf !== false ? 
                            <VisibilityIcon className={classes.visibilityIcon} /> : 
                            <VisibilityOffIcon className={`${classes.visibilityIcon} inactive`} />
                          }
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                      title="Delete Line Item"
                      >
                      <IconButton 
                        size="small" 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(item);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Paper className={classes.formSection}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={classes.formTitle}>
                {editMode === 'new' ? 'Add New Item' : 'Edit Item'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                label="Type"
                value={editingItem.type}
                onChange={handleTypeChange}
                fullWidth
                
                variant="outlined"
              >
                {lineItemTypes.map(type => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          {editingItem.type === 'Materials' && renderMaterialsForm()}
          {(editingItem.type === 'Labor' || editingItem.type === 'Equipment') && renderLaborEquipmentForm()}
          {(editingItem.type === 'Notes' || editingItem.type === 'Measurements') && renderNotesMeasurementsForm(editingItem.type)}
          {editingItem.type === 'Fee' && renderFeeForm()}
          {
            (editingItem?.type) &&
          <Tooltip
            title="Display On PDF"
            >
            
          <FormControlLabel
                control={
                  <Checkbox
                    checked={editingItem.displayOnPdf !== false}
                    onChange={(e) => setEditingItem({ ...editingItem, displayOnPdf: e.target.checked })}
                  />
                }
                label="Display on PDF"
                style={{ marginTop: 10 }}
              />
              </Tooltip>
          }
          {
            (editingItem?.pricePer && editingItem?.qty) ? (
              <Paper className={classes.total}>
                <Typography variant="h6" className={classes.totalText}>
                  Line Item Total: {currencyFormat(calculateTotal(editingItem))}
                </Typography>
              </Paper>
            ) : null 
          }
          
          <div style={{ marginTop: 24 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={!isItemValid(editingItem)}
            >
              {editMode === 'new' ? 'Add Item' : 'Update Item'}
            </Button>
            {editMode === 'edit' && (
              <Button
                variant="outlined"
                onClick={() => {
                  setSelectedItem(null);
                  resetEditingItem();
                  setEditMode('new');
                }}
                style={{ marginLeft: 8 }}
              >
                Cancel Edit
              </Button>
            )}
          </div>
        </Paper>
       
          {/* <TaxModule
              lineItems={lineItems}
              client={client}
              type={'Workorder'}
            /> */}
        {/* <Paper className={classes.taxes}>
          <TaxModule
              lineItems={lineItems}
              type={'Workorder'}
            />
        </Paper> */}
        <Paper className={classes.total}>
         
         
          <Typography variant="h6" className={classes.totalText}>
            Grand Total: {currencyFormat(calculateGrandTotal())}
          </Typography>
        </Paper>
      </DialogContent>

      <DialogActions>
        {
          (editMode !== 'edit') && (
            <>
            <Button onClick={onClose}>
              Cancel
            </Button>
            <Button 
              onClick={handleSaveLineItems} 
              color="primary" 
              variant="contained"
              disabled={lineItems.length === 0}
            >
              Save All Items
            </Button>
            </>
              )
            }
        
      </DialogActions>
    </Dialog>
  );
};

const DescriptionArea = ({ lineItem }) => {
  const classes = useStyles()
  return (
    <div className={classes.descriptionContainer}>
      <div className={classes.descriptionAreaDetails}>
        {lineItem?.description || ''}
      </div>
        {
          lineItem?.internalNotes &&
          <div className={classes.internalNotesAreaDetails}>
            {lineItem?.internalNotes || ''}
          </div>
        }
    </div>
  )
}

const mapStateToProps = (state) => {
  // console.log('MAP PROPS: ,', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(LineItemBuilder)