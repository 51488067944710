import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Grid,
  Chip,
  Button,
  TextField,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Divider,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Print as PrintIcon,
  MonetizationOn as TaxIcon,
  Assignment as PunchlistIcon,
  PhotoCamera as PhotoIcon,
  CloudDone as CompleteIcon,
  ShoppingCart as SuppliesIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Business as VendorIcon,
  People as CrewIcon,
  AttachMoney,
  DateRange,
} from '@material-ui/icons';
import moment from 'moment';
import { handleFocus, currencyFormat } from '../../services/common';
import WorkorderLineItems from './WorkorderLineItems';
import WorkorderPhotos from '../Workorders/WorkorderPhotos';
import MoreActionsMenu from '../Common/MoreActionButtonComponent';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import WarningIcon from '@material-ui/icons/Warning';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import {
  MoreVert as MoreIcon,
  Description as FileIcon,
  CheckBox as ChecklistIcon,
  BugReport as IssuesIcon,
  DateRange as VisitsIcon,
  Add as AddIcon,
  ShoppingCart as ExportIcon,
  Visibility as ViewIcon,
  Visibility
} from '@material-ui/icons';

const PRIORITY_OPTIONS = [
  { value: 'Urgent', label: 'Urgent' },
  { value: 'High', label: 'High' },
  { value: 'Normal', label: 'Normal' },
  { value: 'Low', label: 'Low' },
];

const ASSIGNMENT_TYPES = [
  { value: 'crew', label: 'Internal Crew', icon: CrewIcon },
  { value: 'vendor', label: 'External Vendor', icon: VendorIcon }
];

const useStyles = makeStyles((theme) => ({
  root: {
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  header: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  workorderInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  workorderNumber: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  statusChip: {
    marginLeft: theme.spacing(2),
  },
  priorityChip: {
    marginLeft: theme.spacing(1),
  },
  assignmentChip: {
    marginLeft: theme.spacing(1),
  },
  scrollContainer: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  topSection: {
    marginBottom: theme.spacing(3),
  },
  formRow: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  inputField: {
    flex: 1,
  },
  assignmentTypeField: {
    minWidth: 200,
  },
  dateField: {
    width: 200,
  },
  priceField: {
    width: 150,
  },
  priorityField: {
    width: 120,
  },
  nameField: {
    flex: 2,
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    '& .MuiCardHeader-title': {
      fontSize: '1rem',
    },
  },
  cardContent: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  actionsWrapper: {
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  actionsContainer: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    justifyContent: 'space-evenly'
  },
  textField: {
    '& .MuiInputBase-root': {
      marginTop: 0,
    },
    '& .MuiOutlinedInput-root': {
      '& input': {
        padding: '8px 14px',
      },
    },
    '& .MuiInputBase-inputMultiline': {
      minHeight: '40px !important',
      padding: '8px 14px',
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

const WorkorderDetailsDashboard = ({
  workorder,
  onPrint,
  onEditTaxes,
  onViewPunchlist,
  onViewPhotos,
  onComplete,
  onDelete,
  onViewSupplies,
  onChange,
  editedData,
  punchlistItems = [],
  serviceTypes = [],
  crews = [],
  vendors = [],
  getPriorityColor,
  takeOffList,
  user,
  client,
  job,
  onCreatePO,
  onExportLineItems
}) => {
  const classes = useStyles();
  const [assignmentType, setAssignmentType] = useState(
    editedData.crew ? 'crew' : editedData.vendor ? 'vendor' : 'crew'
  );

  const actions = [
    {
      id: 'createPO',
      label: 'Create PO',
      icon: <LocalAtmIcon fontSize="small" />,
      onClick: (item) => onCreatePO(item)
    },
    {
      id: 'edit',
      label: 'Edit Taxes',
      icon: <AttachMoney fontSize="small" />,
      onClick: (item) => handleEditTaxes(item)
    },
    {
      id: 'exportLineItems',
      label: 'Export Line Items',
      icon: <FileCopyIcon fontSize="small" />,
      onClick: (item) => onExportLineItems(item)
    },
    {
      id: 'serviceVisits',
      label: 'View Service Visits',
      icon: <DateRange fontSize="small" />
    },
    {
      id: 'issues',
      label: 'View Issues',
      icon: <WarningIcon fontSize="small" />
    },
    {
      id: 'delete',
      label: 'Delete',
      icon: <DeleteIcon fontSize="small" />,
      destructive: true,
      onClick: (item) => handleDelete(item)
    },
  ];

  const handleEditTaxes = (d) => {
    console.log('edit handleEditTaxes', d);
    onEditTaxes(d)
  };

  const handleDelete = (d) => {
    console.log('handleDelete', d);
    onDelete(d);
  };

  const handleWOLineItemChange = (data) => {
    const { lineItems, price } = data;
    console.log('Change this shit...', data)
    onChange({
      ...editedData,
      ...data
      // lineItems: lineItems,
      // price
    }, true);
  };

  const handleFieldChange = (field, value) => {
    if (field === 'crew') {
      onChange({
        ...editedData,
        crew: value,
        vendor: null,
      });
    } else if (field === 'serviceType') {
      updateServiceType(value);
    } else if (field === 'vendor') {
      onChange({
        ...editedData,
        vendor: value,
        crew: null,
      });
    } else {
      onChange({
        ...editedData,
        [field]: value,
      });
    }
  };

  const updateServiceType = (svc) => {
    if (svc && svc._id) {
      let newService = {
        serviceCode: svc.serviceCode,
        serviceDescription: svc.description,
        serviceCategory: svc.categoryName,
        serviceName: svc.name
      };
      onChange({
        ...editedData,
        ...newService
      });
    }
  };

  const handleAssignmentTypeChange = (event) => {
    const newType = event.target.value;
    setAssignmentType(newType);
    onChange({
      ...editedData,
      crew: null,
      vendor: null,
    });
  };

  const getStatusColor = () => {
    if (workorder.completedDate) return 'primary';
    if (workorder.workComplete) return 'primary'
    if (workorder.scheduledDate && moment(workorder.scheduledDate).isAfter(moment())) return 'default';
    return 'secondary';
  };

  const getStatusText = () => {
    if (workorder.completedDate) return 'Completed';
    if (workorder.invoiceDate) return 'Invoiced';
    if (workorder.workComplete) return 'Pending Invoicing';
    if (workorder.scheduledDate) return 'Scheduled';
    return 'Pending';
  };

  const getAssigneeDisplay = () => {
    if (editedData.crew) return `Crew: ${editedData.crew.name}`;
    if (editedData.vendor) return `Vendor: ${editedData.vendor.name}`;
    return 'Unassigned';
  };

  const handleComplete = () => {
    onComplete(workorder);
  };

  const handleViewSupplies = () => {
    onViewSupplies(workorder);
  };

  const addNewWOLineItem = () => {
    console.log('Add new wo line item...');
  };

  const punchlistItemsForThisWO = punchlistItems.filter(item => item.workorder === workorder._id);

  let serviceType = null;
  if (editedData?.serviceName && serviceTypes?.length) {
    let index = serviceTypes.findIndex(item => (item.name === editedData.serviceName));
    if (index > -1) serviceType = serviceTypes[index];
  }

  let priorityColor = editedData?.priority ? getPriorityColor(editedData.priority) : { backgroundColor: '#eee', color: '#000' };

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerContent}>
          <div className={classes.workorderInfo}>
            <Typography variant="h5" className={classes.workorderNumber}>
              Workorder #{workorder.number}
              <Chip
                label={getStatusText()}
                color={getStatusColor()}
                className={classes.statusChip}
                size="small"
              />
              {editedData.priority && (
                <Chip
                  label={editedData.priority}
                  style={priorityColor}
                  className={classes.priorityChip}
                  size="small"
                />
              )}
              <Chip
                label={getAssigneeDisplay()}
                className={classes.assignmentChip}
                size="small"
                icon={assignmentType === 'crew' ? <CrewIcon /> : <VendorIcon />}
              />
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Created: {moment(workorder.created_at).format('MM/DD/YYYY')}
            </Typography>
          </div>
        </div>
      </div>

      <div className={classes.scrollContainer}>
        <div className={classes.topSection}>
          <div className={classes.formRow}>
            <TextField
              className={classes.nameField}
              size="small"
              variant="outlined"
              label="Workorder Name"
              value={editedData.name || ''}
              onChange={(e) => handleFieldChange('name', e.target.value)}
            />
            <TextField
              className={classes.priceField}
              size="small"
              variant="outlined"
              label="Price"
              onFocus={handleFocus}
              disabled={true}
              value={editedData.price || ''}
              onChange={(e) => handleFieldChange('price', e.target.value)}
            />
            <TextField
              className={classes.priorityField}
              select
              size="small"
              variant="outlined"
              label="Priority"
              value={editedData.priority || 'Normal'}
              onChange={(e) => handleFieldChange('priority', e.target.value)}
            >
              {PRIORITY_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className={classes.formRow}>
            <Autocomplete
              className={classes.inputField}
              size="small"
              options={serviceTypes}
              getOptionLabel={(option) => 
                `${option.name ? option.name : 'Unknown Service'} (${option.serviceCode})` || ''
              }
              value={serviceType}
              onChange={(_, newValue) => handleFieldChange('serviceType', newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Service Type"
                  size="small"
                />
              )}
            />
            <TextField
              className={classes.dateField}
              size="small"
              variant="outlined"
              type="date"
              label="Scheduled Date"
              value={editedData.scheduledDate ? moment(editedData.scheduledDate).format('YYYY-MM-DD') : ''}
              onChange={(e) => handleFieldChange('scheduledDate', e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className={classes.formRow}>
            <FormControl variant="outlined" size="small" className={classes.assignmentTypeField}>
              <InputLabel>Assignment Type</InputLabel>
              <Select
                value={assignmentType}
                onChange={handleAssignmentTypeChange}
                label="Assignment Type"
              >
                {ASSIGNMENT_TYPES.map(type => {
                  const Icon = type.icon;
                  return (
                    <MenuItem key={type.value} value={type.value}>
                      <div className={classes.menuItem}>
                        <Icon fontSize="small" />
                        {type.label}
                      </div>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            {assignmentType === 'crew' ? (
              <Autocomplete
                className={classes.inputField}
                size="small"
                options={crews || []}
                value={editedData.crew || null}
                getOptionLabel={(option) => 
                  `${option.name ? option.name : 'Unknown Crew'}`
                }
                onChange={(_, newValue) => handleFieldChange('crew', newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Assigned Crew"
                    size="small"
                  />
                )}
              />
            ) : (
              <Autocomplete
                className={classes.inputField}
                size="small"
                options={vendors || []}
                value={editedData.vendor || null}
                getOptionLabel={(option) => 
                  `${option.name ? option.name : 'Unknown Vendor'}`
                }
                onChange={(_, newValue) => handleFieldChange('vendor', newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Assigned Vendor"
                    size="small"
                  />
                )}
              />
            )}
          </div>
        </div>

        <Card className={classes.card}>
          <CardHeader title="Notes & Comments" className={classes.cardHeader} />
          <CardContent className={classes.cardContent}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  multiline
                  onFocus={handleFocus}
                  rows={2}
                  label="Description"
                  value={editedData.serviceDescription || ''}
                  onChange={(e) => handleFieldChange('serviceDescription', e.target.value)}
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  multiline
                  onFocus={handleFocus}
                  rows={2}
                  label="Workorder Notes"
                  value={editedData.serviceNotes || ''}
                  onChange={(e) => handleFieldChange('serviceNotes', e.target.value)}
                  className={classes.textField}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {
              (editedData.number && editedData.number !== 'TBD') &&
            <Card className={classes.card}>
             
              <CardContent className={classes.cardContent}>
                
                
                    <WorkorderLineItems 
                      workorder={editedData}
                      addNewWOLineItem={addNewWOLineItem}
                      onChange={handleWOLineItemChange}
                      user={user}
                      vendors={vendors || []}
                      takeOffList={takeOffList}
                      client={client}
                      job={job}
                    />
                
              </CardContent>
            </Card>
        }
      </div>

      <div className={classes.actionsWrapper}>
        <div className={classes.actionsContainer}>
          <Button
            size="small"
            variant="contained"
            startIcon={<PrintIcon />}
            onClick={() => onPrint(workorder)}
          >
            Print
          </Button>
          <Button
            size="small"
            variant="contained"
            startIcon={<PunchlistIcon />}
            onClick={() => onViewPunchlist(workorder)}
          >
            Punchlist ({punchlistItemsForThisWO?.length || 0})
          </Button>
          <WorkorderPhotos workorder={workorder} />
          <Button
            size="small"
            variant="contained"
            startIcon={<SuppliesIcon />}
            onClick={handleViewSupplies}
          >
            Supplies ({workorder?.supplies?.length || 0} - {workorder?.suppliesUsed?.length || 0})
          </Button>
          {!workorder.completedDate && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<CompleteIcon />}
              onClick={handleComplete}
            >
              Complete
            </Button>
          )}
          <MoreActionsMenu
            actions={actions}
            selectedItem={workorder}
            tooltipText="More Actions"
          />
        </div>
      </div>
    </Paper>
  );
};

export default WorkorderDetailsDashboard;
